body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #5f6368;
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #202124;
}
a:hover {
  text-decoration: none;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

.nav-sidebar .list-group-item {
  padding: 0.5rem 1.25rem;
}
.nav-sidebar .list-group-item-action {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}
.nav-sidebar .list-group-item-action.active,
.btn-purple {
  color: rgba(98, 0, 238, 0.87);
  background-color: #ede1fd !important;
}

.card {
  -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.btn,
.form-control {
  font-size: 14px;
}
.form-control {
  border-radius: 0;
}
.btn {
  border-radius: 0 !important;
}
.btn:not([disabled]):not(.disabled) {
  cursor: pointer;
}

.f-13 {
  font-size: 13px;
}
.f-15 {
  font-size: 15px;
}

.bg-purple {
  color: rgba(98, 0, 238, 0.87);
  background-color: #ede1fd;
}

.ck-editor__editable {
  min-height: 300px !important;
}

@media only screen and (max-width: 768px) {
  .form-control.w-25,
  .form-control.w-50,
  .form-control.w-75,
  .input-group.w-25,
  .media.w-50,
  .alert.w-50 {
    width: 100% !important;
  }
  .brand-container .navbar {
    margin: 0;
    padding: 0;
  }
  .brand-container .navbar-brand {
    padding: 5px 0;
  }
  .brand-img {
    height: 50px;
  }
  .brand-text {
    font-size: 14px;
  }

  .res-tbl {
    border: 0;
    border-collapse: collapse;
  }
  .res-tbl,
  .res-tbl thead,
  .res-tbl tbody,
  .res-tbl th,
  .res-tbl td,
  .res-tbl tr {
    display: block;
  }
  .res-tbl thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    overflow: hidden;
  }
  .res-tbl tbody tr {
    margin: 0 0 15px 0;
    display: flex;
    flex-flow: column;
  }
  .res-tbl tr td {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #efefef;
    position: relative;
    padding-left: 40%;
    word-wrap: break-word;
    background: #fff;
  }
  .res-tbl tr td:last-child {
    border-bottom: none;
  }
  ​.res-tbl tr td:first-child {
    border-top: 1px solid #ddd;
  }
  .res-tbl td:before {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 55%;
    padding-right: 10px;
    white-space: nowrap;
    content: attr(data-label);
    text-align: left;
  }
}
